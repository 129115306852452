import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Flex,
} from '@chakra-ui/core';
import { KeyValuePair } from '.';
import { IdVerification, Maybe } from '../../../_generated/types';
import strings from '../../constants/strings';

const IdVerificationErrorTooltip = (
  idVerification: Maybe<IdVerification | undefined>,
  children: React.ReactElement,
) => {
  if (!idVerification?.isValid && idVerification?.nameCheckFailed && idVerification?.metadata) {
    const { idLastName, idFirstName } = idVerification.metadata;

    if (!idLastName || !idFirstName) {
      return children;
    }

    return (
      <Popover trigger="hover">
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader fontWeight="600"> {strings.IdVerificationBadge.tooltip}</PopoverHeader>
          <PopoverBody>
            <Flex direction="column">
              <KeyValuePair label={'First name'} value={idFirstName} gap="1" />
              <KeyValuePair label={'Last name'} value={idLastName} gap="1" />
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
  return children;
};

export default IdVerificationErrorTooltip;
